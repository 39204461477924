<script setup>
import Thubmnail from '@/dashboard/components/thumbnail/ComponentOne'
import { ref } from 'vue'
import { useAuthStore } from '@/store/auth'
import { useRouter } from 'vue-router'

const authStore = useAuthStore()
const router = useRouter()
const logoutAttemptMsg = ref()
const open = ref(false)
const user = JSON.parse(localStorage.getItem('user'))

function onMenuClick() {
    open.value = !open.value
}

function logout() {
    authStore.logout().then(response => {
        if(response == true) {
            router.push('/login')
            logoutAttemptMsg.value = "Logout was Successful"
        } else {
            logoutAttemptMsg.value = "Logout Attempt was Unsuccessful, Please try again"
        }
    })
}
</script>

<template>
    <div class="user-menu">
        <div @click="onMenuClick" class="menu-title">
            <Thubmnail />
            {{ user.userInfo.name }}
            <span v-if="open"><font-awesome-icon :icon="['fas', 'caret-up']" /></span>
            <span v-else><font-awesome-icon :icon="['fas', 'caret-down']" /></span>
        </div>
        <div v-if="open" class="menu-content">
            <div class="menu-item"><font-awesome-icon :icon="['fas', 'gear']" /> Settings</div>
            <div @click="logout" class="menu-item"><font-awesome-icon :icon="['fas', 'right-from-bracket']" /> Logout</div>
        </div>
    </div>
</template>

<style scoped>
.user-menu {
    height: 100%;
    display: grid;
    position: relative;
    cursor: pointer;
    background: #1d384d;
    padding: 0 15px;
}
.user-menu > .menu-title {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 13px;
    font-weight: bold;
}

.user-menu > .menu-content {
    background: #365167;
    box-shadow: #00000026 1.95px 1.95px 2.6px;
    top: 7.9vh;
    position: absolute;
    min-width: 100%;
}

.user-menu > .menu-content > .menu-item {
    color: white;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    padding: 10px 20px;
}
.user-menu > .menu-content > .menu-item:hover {
    background-color: #273b4b;
}

.user-menu > .menu-content > .menu-item:not(:last-child) {
    border-bottom: 1px dashed gray;
}
</style>