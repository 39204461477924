import { onBeforeMount, onMounted } from "vue";

export function useClickOutside(ref, callback) {
  if(!ref) return;

  let listener = (e) => {
    if(e.target == ref.value || e.composedPath().includes(ref.value)) {
       return
    }

    if(typeof callback == 'function') {
       callback()
    }
  }

  onMounted(() => {
    window.addEventListener('click', listener)
  })

  onBeforeMount(() => {
    window.removeEventListener('click', listener)
  })

  return {
    listener
  }
}