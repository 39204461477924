<script setup>
import Search from '@/website/components/search/ComponentOne'
import { useCartStore } from '@/store/cart'

const cartStore = useCartStore()
</script>

<template>
    <div class="web-header">
       <router-link to="/" class="logo">
           <img src="@/website/assets/images/logo.png" />
       </router-link>
       <Search />
       <div class="navmenu">
           <!-- <router-link to=""><font-awesome-icon :icon="['fas', 'heart']" /></router-link> -->
           <router-link class="cart" to="/cart">
            <span class="quantity">
                {{ cartStore.cart.count }}
            </span>
            <font-awesome-icon :icon="['fas', 'cart-shopping']" />
           </router-link>
           <router-link to="/login">Shop</router-link>
           <router-link to="/login">Login</router-link>
       </div>
    </div>
</template>

<style scoped>
.web-header {
    height: 10vh;
    background-color: #1b2b37;
    padding: 0 5%;
    display: grid;
    grid-template-columns: 0.1fr 1fr 0.2fr;
    grid-template-areas:
        'logo search navmenu'
        ;
    gap: 50px;
}

.web-header > * {
    align-self: center;
}

.logo {
    height: 10vh;
    display: flex;
    align-items: center;
    grid-area: logo;
}

.logo > img {
    height: 75%;
}

.navmenu {
    display: flex;
    justify-content: end;
    gap: 20px;
    grid-area: navmenu;
}

.navmenu > *:hover {
    color: #a5a3a3;
}

.navmenu > * {
    text-decoration: none;
    color: #bebebe;
}

.cart {
    position: relative;
}

.quantity {
    position: absolute;
    height: 15px;
    width: 15px;
    margin-top: -10px;
    margin-left: 10px;
    background-color: #ff3030;
    border-radius: 50%;
    color: white;
    font-size: 12px;
    font-weight: bold;

    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 1024px) {
     
}


@media only screen and (max-width: 600px) {
    /* For mobile phones: */
    .web-header {
        height: 25vh;
        gap: 0px;
        grid-template-columns: 0.2fr 1fr;
        grid-template-areas:
        'logo navmenu'
        'mini-search mini-search'
        ;
    }
}
</style>