<template>
    <div class="container">
        <div class="login-panel">
            <slot />
        </div>
    </div>
</template>

<style scoped>
.container {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #070d12;
}

.login-panel {
    width: 35%;
    background-color: #101f2b;
}

@media only screen and (max-width: 600px) {
    /* For tablets: */
    .login-panel {
        width: 70%;
        background-color: #101f2b;
    }
}
@media only screen and (max-width: 450px) {
    /* For mobile phones: */
    .login-panel {
        width: 80%;
        background-color: #101f2b;
    }
}
</style>