import { defineStore } from 'pinia';
import axios from 'axios'

export const useWebsiteStore = defineStore({
    id: 'website',
    state: () => ({
        catProducts: [],
        productDetails: {},
        trendingProducts: []
    }),
    actions: {
        async getCatProducts() {
            const resp = await axios.get(process.env.VUE_APP_BASE_API_URL+'cat-products')
            this.catProducts = resp.data
            console.log(this.catProducts)
        },

        async trending() {
            const resp = await axios.get(process.env.VUE_APP_BASE_API_URL+'trending')
            this.trendingProducts = resp.data
        },

        async getProductDetails(id) {
            const resp = await axios.get(process.env.VUE_APP_BASE_API_URL+'product/'+id)
            this.productDetails = resp.data
        }
    }
});

