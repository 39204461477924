<template>
  <component :is="$route.meta.layout || 'div'">
    <router-view></router-view>
  </component>
</template>

<style>
html {
  scroll-behavior: smooth;
}

body {
  margin: auto;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

label {
  font-size: 13px;
}
/* Scrollbar design */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: red; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000; 
}

/* Crollbar design ends */

/* Multiverse */
/* Input, buttons etc */

.primary-input {
  border: none;
  outline: none;
  padding: 15px;
  border-radius: 5px;
  background-color: #0b161e;
  color: white;
}

.primary-input:focus {
  background-color: #080f14;
}

.primary-input:-webkit-autofill,
.primary-input:-webkit-autofill:hover, 
.primary-input:-webkit-autofill:focus, 
.primary-input:-webkit-autofill:active{
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #080f14;
}

.primary-textarea {
  border: none;
  outline: none;
  padding: 15px;
  border-radius: 5px;
  background-color: #0b161e;
  color: white;
  font-family: inherit !important;
}

.primary-textarea:focus {
  background-color: #080f14;
}

.primary-select {
  border: none;
  outline: none;
  padding: 15px;
  border-radius: 5px;
  background-color: #0b161e;
  color: white;
}

.primary-select:focus {
  background-color: #080f14;
}

.primary-select > option {
  outline: none;
  border: none;
  padding: 10px !important;
  height: 50px !important;
  background: #080f14 !important;
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

.primary-select > option > * {
  padding: 15px;
}

/*  button */
.create-btn {
  text-decoration: none;
  color: white;
  background-color: green;
  border-color: rgb(1, 107, 1);
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 12px 20px;
  font-size: 12px;
  cursor: pointer;
}

.create-btn:hover {
  background-color: rgb(1, 107, 1);
}

.cancel-btn {
  text-decoration: none;
  color: white;
  background-color: #ff3939;
  border-color: #e73333;
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 12px 20px;
  font-size: 12px;
  cursor: pointer;
}

.cancel-btn:hover {
  background-color: #e73333;
}

.blue-btn {
  outline: none;
  border: none;
  text-decoration: none;
  color: white;
  background-color: #2c3a85;
  border-color: #25306e;
  border: none;
  outline: none;
  padding: 12px 20px;
  font-size: 12px;
  cursor: pointer;
}

.blue-btn:hover {
  background-color: #25306e;
}

.error-msg {
  font-size: 13px;
  color: #f17272;
}

.paginate-btn {
  text-decoration: none;
  color: white;
  background-color: #2e3d45;
  border-color: #243036;
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 8px 15px;
  font-size: 12px;
  cursor: pointer;
}

.paginate-btn:hover {
  background-color: #243036;
}
</style>
