<script setup>
import { ref } from 'vue'
import { useClickOutside } from '@/composables/useClickOutside'
import axios from 'axios'
import Loader from '@/dashboard/components/spinner/RollerComponent'
import { useWebsiteStore } from '@/store/website'
import { useRouter } from 'vue-router'

const router = useRouter()
const websiteStore = useWebsiteStore()
const click_outside = ref(null)
const onClick = ref(false)
const suggestions = ref()
const keyWords = ref()

async function searchBarFocused() {
    onClick.value = !onClick.value
    await axios.get(process.env.VUE_APP_BASE_API_URL+'search').then(response => {
        suggestions.value = response.data.data
    })
}

async function search() {
    suggestions.value = {}
    await axios.get(process.env.VUE_APP_BASE_API_URL+'search/'+keyWords.value).then(response => {
        suggestions.value = response.data.data
    })
}

function redirect(id) {
    router.push('/product/' + id)
    websiteStore.getProductDetails(id)
}

function clear() {
    onClick.value = false
    keyWords.value = ''
}

useClickOutside(click_outside, ()=> {
    onClick.value = false
})
</script>
<template>
    <div ref="click_outside" class="search">
        <input @focus="searchBarFocused()" @keyup="search" type="text" v-model="keyWords" placeholder="Search products ..." />
        <div class="search-icon">
            <font-awesome-icon v-if="onClick == false" @click="searchBarFocused()" :icon="['fas', 'magnifying-glass']" />
            <font-awesome-icon @click="clear" v-else :icon="['fas', 'xmark']" />
        </div>
        <div v-if="onClick == true" class="search-suggestion">
            <div v-if="suggestions" class="search-result">
                <div v-for="suggestion in suggestions" :key="suggestion" class="item">
                    <div class="image" v-html="suggestion.image">
                    </div>
                    <div class="title">{{ suggestion.name }}</div>
                    <div class="variant"><a @click="redirect(suggestion.id)">Check it's variants and prices</a></div>
                </div>
            </div>
            <div v-else class="loader-position">
                <Loader />
            </div>
        </div>
    </div>
</template>

<style scoped>
.item {
    display: flex;
    align-items: center;
    gap: 15px;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    margin: 20px;
    padding: 5px;
}

.item > .title {
    font-size: 18px;
    font-weight: bold;
    color: rgb(67, 69, 105);
}
.loader-position {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

}
.search {
    position: relative;
}

.search input {
    position: absolute;
    outline: none;
    border: none;
    height: 30px;
    width: 660px;
    border-radius: 5px;
    padding: 5px 20px;
    font-size: 17px;
    background-color: white;
    color: black;
    top: -20px;
}

.search-icon {
    position: absolute;
    left: 670px;
    top: -7px;
    color: black;
    font-weight: bold;
    cursor: pointer;
}

.search-suggestion {
    position: absolute;
    margin-top: 20px;
    height: 400px; /* Set your desired max-height */
    overflow: auto;   /* or overflow: scroll; */
    width: 700px;
    z-index: 9;
    background-color: white;
    border-radius: 0 0 25px 25px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

a {
    text-decoration: underline;
    font-weight: bold;
    color: #07bb07;
    cursor: pointer;
}

@media only screen and (max-width: 1024px) {
    .search input {
        width: 460px;
    }

    .search-suggestion {
        width: 501px;
    }

    .search-icon {
        left: 474px;
    }
}


@media only screen and (max-width: 600px) {
    /* For mobile phones: */
    .search {
        display: none;
    }
}
</style>