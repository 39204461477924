<script setup>
import { ref } from 'vue'

const open = ref('')

function onMenuClick(menu) {
    if(open.value == menu) {
        open.value = ''
    } else {
        open.value = menu
    }
}
</script>

<template>
    <div class="sidebar">
        <router-link to="/" class="brand">
            <img src="@/website/assets/images/logo.png" />
        </router-link>
        <div class="sidebar-menu">
            <router-link to="/dashboard" class="menu-item">
                <div :class="($route.path === '/dashboard') ? 'parent active' : 'parent'">
                    <font-awesome-icon :icon="['fas', 'home']" /> Dashboard
                 </div>
            </router-link>
            <div class="menu-item">
                <div @click="onMenuClick('user')" :class="($route.path == '/user') ? 'parent active' : 'parent'">
                   <font-awesome-icon :icon="['fas', 'user']" /> 
                   HR & Admin 
                   <div>
                       <span v-if="$route.path == '/dashboard/user' || open == 'user'"><font-awesome-icon :icon="['fas', 'caret-up']" /></span>
                       <span v-else><font-awesome-icon :icon="['fas', 'caret-down']" /></span>
                   </div>
                </div>
                <div v-if="$route.path == '/dashboard/user' || open == 'user'" class="child">
                    <router-link to="/dashboard/user" :class="($route.path == '/dashboard/user') ? 'child-item child-active' : 'child-item'">User</router-link>
                    <router-link to="/dashboard/supplier" :class="($route.path == '/dashboard/supplier') ? 'child-item child-active' : 'child-item'">Supplier</router-link>
                    <!-- <router-link to="/dashboard/role" :class="($route.path == '/dashboard/role') ? 'child-item child-active' : 'child-item'">Role</router-link>
                    <router-link to="/dashboard/permission" :class="($route.path == '/dashboard/permission') ? 'child-item child-active' : 'child-item'">Permission</router-link> -->
                </div>
            </div>
            <div class="menu-item">
                <div @click="onMenuClick('product')" :class="($route.path == '/product') ? 'parent active' : 'parent'">
                   <font-awesome-icon :icon="['fas', 'layer-group']" /> 
                   Product Manager
                   <div>
                       <span v-if="$route.path == '/product' || open == 'product'"><font-awesome-icon :icon="['fas', 'caret-up']" /></span>
                       <span v-else><font-awesome-icon :icon="['fas', 'caret-down']" /></span>
                   </div>
                </div>
                <div v-if="$route.path == '/product' || open == 'product'" class="child">
                    <router-link to="/dashboard/category" :class="($route.path == '/dashboard/category') ? 'child-item child-active' : 'child-item'">Category</router-link>
                    <router-link to="/dashboard/variant" :class="($route.path == '/dashboard/variant') ? 'child-item child-active' : 'child-item'">Variant</router-link>
                    <router-link to="/dashboard/product" :class="($route.path == '/dashboard/product') ? 'child-item child-active' : 'child-item'">Product</router-link>
                    <router-link to="/dashboard/supply" :class="($route.path == '/dashboard/supply') ? 'child-item child-active' : 'child-item'">Supply</router-link>
                    <!-- <router-link to="/dashboard/transfer" :class="($route.path == '/dashboard/transfer') ? 'child-item child-active' : 'child-item'">Transfer</router-link>
                    <router-link to="/dashboard/warehouse" :class="($route.path == '/dashboard/warehouse') ? 'child-item child-active' : 'child-item'">Warehouse(Stock)</router-link> -->
                </div>
            </div>
            <div class="menu-item">
                <div @click="onMenuClick('storage')" :class="($route.path == '/storage') ? 'parent active' : 'parent'">
                   <font-awesome-icon :icon="['fas', 'cube']" /> 
                   Stock Manager
                   <div>
                       <span v-if="$route.path == '/storage' || open == 'storage'"><font-awesome-icon :icon="['fas', 'caret-up']" /></span>
                       <span v-else><font-awesome-icon :icon="['fas', 'caret-down']" /></span>
                   </div>
                </div>
                <div v-if="$route.path == '/storage' || open == 'storage'" class="child">
                    <router-link to="/dashboard/storage-type" :class="($route.path == '/dashboard/storage-type') ? 'child-item child-active' : 'child-item'">Storage Type</router-link>
                    <router-link to="/dashboard/storage" :class="($route.path == '/dashboard/storage') ? 'child-item child-active' : 'child-item'">Storage</router-link>
                </div>
            </div>
            <router-link to="/dashboard/orders" class="menu-item">
                <div :class="($route.path === '/dashboard/orders') ? 'parent active' : 'parent'">
                    <font-awesome-icon :icon="['fas', 'list']" /> Orders
                 </div>
            </router-link>
            <router-link to="/dashboard/sales" class="menu-item">
                <div :class="($route.path === '/dashboard/sales') ? 'parent active' : 'parent'">
                    <font-awesome-icon :icon="['fas', 'dollar-sign']" /> Sales
                 </div>
            </router-link>
            <router-link to="/dashboard/payment-methods" class="menu-item">
                <div :class="($route.path === '/dashboard/payment-methods') ? 'parent active' : 'parent'">
                    <font-awesome-icon :icon="['fas', 'money-check-dollar']" /> Payment Gateways
                 </div>
            </router-link>
            <!-- <router-link to="/dashboard/waste" class="menu-item">
                <div :class="($route.path === '/dashboard/waste') ? 'parent active' : 'parent'">
                    <font-awesome-icon :icon="['fas', 'trash']" /> Waste
                 </div>
            </router-link> -->
            <!-- <div class="menu-item"> -->
                <!-- Money can come from three sources: Investors, donation, revenue -->
                <!-- Money can go away of three reasons: Pucrahsing products, Buying stuff(warehouse, fan, light etc), Employee salary(recurring) -->
                <!-- <div @click="onMenuClick('storage')" :class="($route.path == '/storage') ? 'parent active' : 'parent'">
                   <font-awesome-icon :icon="['fas', 'cube']" /> 
                   Accounts Manager
                   <div>
                       <span v-if="$route.path == '/storage' || open == 'storage'"><font-awesome-icon :icon="['fas', 'caret-up']" /></span>
                       <span v-else><font-awesome-icon :icon="['fas', 'caret-down']" /></span>
                   </div>
                </div>
                <div v-if="$route.path == '/storage' || open == 'storage'" class="child">
                    <router-link to="/dashboard/storage-type" :class="($route.path == '/dashboard/storage-type') ? 'child-item child-active' : 'child-item'">Storage Type</router-link>
                    <router-link to="/dashboard/storage" :class="($route.path == '/dashboard/storage') ? 'child-item child-active' : 'child-item'">Storage</router-link>
                </div>
            </div>
            <router-link to="/dashboard/settings" class="menu-item">
                <div :class="($route.path === '/dashboard/settings') ? 'parent active' : 'parent'">
                    <font-awesome-icon :icon="['fas', 'gear']" /> Store setup
                 </div>
            </router-link> -->
        </div>
    </div>
</template>

<style scoped>
.sidebar {
    background-color: #1b2b37;
}

.brand {
    height: 10vh;
    display: flex;
    align-items: center;
    padding: 0 20px;
    color: gray;
    font-size: 30px;
    font-weight: bold;
}

.brand > * {
    width: 100%;
}

.sidebar-menu {
    height: 90vh;
    overflow-y: auto;
}

.sidebar-menu > .menu-item {
    text-decoration: none;
}

.sidebar-menu > .menu-item:hover {
    color: white;
}

.sidebar-menu > .menu-item > .parent {
    padding: 15px 20px;
    font-size: 13px;
    color: #afafaf;
    cursor: pointer;
    display: grid;
    grid-template-columns: 0.1fr 0.8fr 0.1fr;
    gap: 10px;
    font-weight: bold;
}

.sidebar-menu > .menu-item > .parent:hover {
    color: white;
}

.menu-item > .child {
    background-color: #16232d;
    display: grid;
}

.menu-item > .child > .child-item {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 40px;
    color: #dfdfdf;
    cursor: pointer;
    font-size: 13px;
    font-weight: bold;
    transition: 0.1s ease-in-out;
    text-decoration: none;
}

.menu-item > .child > .child-item:hover {
    background-color: #334e63;
}

.menu-item > .child > .child-item:not(:last-child) {
    border-bottom: 1px dashed black;
}

.active {
    color: white !important;
}

.child-active {
    background-color: #334e63 !important;
}
</style>