<script setup>
import { ref, defineProps } from 'vue'

const open = ref('')

function onMenuClick(menu) {
    if(open.value == menu) {
        open.value = ''
    } else {
        open.value = ''
        open.value = menu
    }
}

defineProps({
    menuName: String
})
</script>

<template>
    <div class="user-menu">
        <div @click="onMenuClick(menuName)" class="menu-title">
            <slot name="menuTitle" />
        </div>
        <div v-if="open == menuName" class="menu-content">
            <div class="menu-item"><slot name="menuCcontent" /></div>
        </div>
    </div>
</template>

<style scoped>
.user-menu {
    height: 100%;
    display: grid;
    position: relative;
    cursor: pointer;
}

.user-menu:focus {
    background: #1d384d;
}
.user-menu > .menu-title {
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: bold;
}

.user-menu > .menu-content {
    right: -20px;
    background: #365167;
    box-shadow: #00000026 1.95px 1.95px 2.6px;
    top: 7.9vh;
    position: absolute;
    min-width: 100%;
}

.user-menu > .menu-content > .menu-item {
    color: white;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    padding: 10px 20px;
}

.user-menu > .menu-content > .menu-item:hover {
    background-color: #273b4b;
}

.user-menu > .menu-content > .menu-item:not(:last-child) {
    border-bottom: 1px dashed gray;
}
</style>