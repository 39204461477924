import { createApp } from 'vue'
import App from './App.vue'
import Router from './router/index'

import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import '@/website/assets/js/slider.js'
import { createPinia } from 'pinia';
import print from 'vue3-print-nb'

library.add(fas, far, fab)
dom.watch();

createApp(App)
.component('font-awesome-icon', FontAwesomeIcon)
.use(Router)
.use(print)
.use(createPinia())
.mount('#app')
