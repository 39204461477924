<template>
    <div class="footer">
        <div class="footer-header">Contact Us</div>
        <div class="footer-content">
            <div class="contacts">
                <div class="phone"><font-awesome-icon :icon="['fas', 'phone']" /> (880) 1635860408</div>
                <div class="vr-divider"></div>
                <div class="email"><font-awesome-icon :icon="['fas', 'envelope']" /> support@bketon.com</div>
            </div>
            <div class="address">
                <font-awesome-icon :icon="['fas', 'location-dot']" /> Road#18, Sector-12(Moylar Mor), Uttara, Dhaka-1230
            </div>
            <div class="copyright">
                2024 <span class="copyright-icon">&#169;</span> bketon.com 
            </div>
        </div>
    </div>
</template>

<style scoped>
.vr-divider {
    width: 2px;
    height: 100%;
    background-color: #a8a8a8;
}
.footer {
    display: grid;
    gap: 10px;
    padding: 30px;
    background-color: #eeeeee;
}

.footer > * {
    justify-self: center;
}

.footer-header {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
}

.footer-content {
    display: grid;
    gap: 10px;
    font-size: 13px;
}

.footer-content > * {
    justify-self: center;
}

.contacts {
    display: flex;
    gap: 10px;
}
</style>