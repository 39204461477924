import { createRouter, createWebHistory } from 'vue-router'
import DashboardLayout from '@/dashboard/layout/DashboardLayout'
import GeneralLayout from '@/dashboard/layout/GeneralLayout'
import WebsiteLayout from '@/website/layouts/WebsiteLayout'

const routes = [
    {
        path: '/',
        name: 'LandingPage',
        component: () => import('@/website/modules/LandingPage'), 
        meta: { layout: WebsiteLayout }
    },
    {
        path: '/product/:id',
        name: 'ProductPage',
        component: () => import('@/website/modules/ProductPage'), 
        meta: { layout: WebsiteLayout }
    },
    {
        path: '/category/:id',
        name: 'CategoryPage',
        component: () => import('@/website/modules/CategoryPage'), 
        meta: { layout: WebsiteLayout }
    },
    {
        path: '/cart',
        name: 'CartPage',
        component: () => import('@/website/modules/CartPage'), 
        meta: { layout: WebsiteLayout }
    },
    {
        path: '/search',
        name: 'SearchPage',
        component: () => import('@/website/modules/SearchPage'), 
        meta: { layout: WebsiteLayout }
    },
    {
        path: '/invoice',
        name: 'ClientInvoicePage',
        component: () => import('@/website/modules/InvoicePage'), 
        meta: { layout: WebsiteLayout }
    },
    {
        path: '/login',
        name: 'LoginPage',
        component: () => import('@/dashboard/modules/auth/LoginPage'), 
        meta: { layout: GeneralLayout }
    },
    {
       path: '/dashboard',
       name: 'Dashboard',
       component: () => import('@/dashboard/modules/DashboardPage'), 
       meta: { layout: DashboardLayout },
       beforeEnter: (to, from, next) => {
            // Your access control logic here
            if (userIsAuthenticated()) {
            next(); // Allow access
            } else {
            next('/login'); // Redirect to login page
            }
        },
    },
    // Admin
    {
        path: '/dashboard/user',
        name: 'UserList',
        component: () => import('@/dashboard/modules/user/ListPage'), 
        meta: { layout: DashboardLayout },
        beforeEnter: (to, from, next) => {
            // Your access control logic here
            if (userIsAuthenticated()) {
            next(); // Allow access
            } else {
            next('/login'); // Redirect to login page
            }
        },
    },
    {
        path: '/dashboard/user/create',
        name: 'UserCreatePage',
        component: () => import('@/dashboard/modules/user/CreatePage'), 
        meta: { layout: DashboardLayout },
        beforeEnter: (to, from, next) => {
            // Your access control logic here
            if (userIsAuthenticated()) {
              next(); // Allow access
            } else {
              next('/login'); // Redirect to login page
            }
        },
    },
    {
        path: '/dashboard/user/edit/:id',
        name: 'UserEditPage',
        component: () => import('@/dashboard/modules/user/CreatePage'), 
        meta: { layout: DashboardLayout },
        beforeEnter: (to, from, next) => {
            // Your access control logic here
            if (userIsAuthenticated()) {
              next(); // Allow access
            } else {
              next('/login'); // Redirect to login page
            }
        },
    },
    // supplier
    {
        path: '/dashboard/supplier',
        name: 'SupplierList',
        component: () => import('@/dashboard/modules/supplier/ListPage'), 
        meta: { layout: DashboardLayout },
        beforeEnter: (to, from, next) => {
            // Your access control logic here
            if (userIsAuthenticated()) {
            next(); // Allow access
            } else {
            next('/login'); // Redirect to login page
            }
        },
    },
    {
        path: '/dashboard/supplier/create',
        name: 'SupplierCreatePage',
        component: () => import('@/dashboard/modules/supplier/CreatePage'), 
        meta: { layout: DashboardLayout },
        beforeEnter: (to, from, next) => {
            // Your access control logic here
            if (userIsAuthenticated()) {
              next(); // Allow access
            } else {
              next('/login'); // Redirect to login page
            }
        },
    },
    {
        path: '/dashboard/supplier/edit/:id',
        name: 'SupplierEditPage',
        component: () => import('@/dashboard/modules/supplier/CreatePage'), 
        meta: { layout: DashboardLayout },
        beforeEnter: (to, from, next) => {
            // Your access control logic here
            if (userIsAuthenticated()) {
              next(); // Allow access
            } else {
              next('/login'); // Redirect to login page
            }
        },
    },
    {
        path: '/dashboard/role',
        name: 'RoleList',
        component: () => import('@/dashboard/modules/role/ListPage'), 
        meta: { layout: DashboardLayout }
    },
    {
        path: '/dashboard/role/create',
        name: 'RoleCreatePage',
        component: () => import('@/dashboard/modules/role/CreatePage'), 
        meta: { layout: DashboardLayout }
    },
    {
        path: '/dashboard/role/edit/:id',
        name: 'RoleEditPage',
        component: () => import('@/dashboard/modules/role/CreatePage'), 
        meta: { layout: DashboardLayout }
    },
    {
        path: '/dashboard/permission',
        name: 'PermissionList',
        component: () => import('@/dashboard/modules/permission/ListPage'), 
        meta: { layout: DashboardLayout }
    },
    {
        path: '/dashboard/permission/create',
        name: 'PermissionCreatePage',
        component: () => import('@/dashboard/modules/permission/CreatePage'), 
        meta: { layout: DashboardLayout }
    },
    {
        path: '/dashboard/permission/edit/:id',
        name: 'PermissionEditPage',
        component: () => import('@/dashboard/modules/permission/CreatePage'), 
        meta: { layout: DashboardLayout }
    },
    {
        path: '/dashboard/payment-methods',
        name: 'PaymentMethodList',
        component: () => import('@/dashboard/modules/payment/ListPage'), 
        meta: { layout: DashboardLayout },
        beforeEnter: (to, from, next) => {
            // Your access control logic here
            if (userIsAuthenticated()) {
              next(); // Allow access
            } else {
              next('/login'); // Redirect to login page
            }
        },
    },
    {
        path: '/dashboard/payment-methods/:id/settings',
        name: 'SetPaymentMethod',
        component: () => import('@/dashboard/modules/payment/EditPage'), 
        meta: { layout: DashboardLayout }
    },
    // Product
    {
        path: '/dashboard/product',
        name: 'ProductList',
        component: () => import('@/dashboard/modules/product/ListPage'), 
        meta: { layout: DashboardLayout },
        beforeEnter: (to, from, next) => {
            // Your access control logic here
            if (userIsAuthenticated()) {
              next(); // Allow access
            } else {
              next('/login'); // Redirect to login page
            }
        },
    },
    {
        path: '/dashboard/product/create',
        name: 'ProductCreatePage',
        component: () => import('@/dashboard/modules/product/CreatePage'), 
        meta: { layout: DashboardLayout },
        beforeEnter: (to, from, next) => {
            // Your access control logic here
            if (userIsAuthenticated()) {
              next(); // Allow access
            } else {
              next('/login'); // Redirect to login page
            }
        },
    },
    {
        path: '/dashboard/product/edit/:id',
        name: 'ProductEditPage',
        component: () => import('@/dashboard/modules/product/EditPage'), 
        meta: { layout: DashboardLayout },
        beforeEnter: (to, from, next) => {
            // Your access control logic here
            if (userIsAuthenticated()) {
              next(); // Allow access
            } else {
              next('/login'); // Redirect to login page
            }
        },
    },
    {
        path: '/dashboard/category',
        name: 'CategoryList',
        component: () => import('@/dashboard/modules/category/ListPage'), 
        meta: { layout: DashboardLayout },
        beforeEnter: (to, from, next) => {
            // Your access control logic here
            if (userIsAuthenticated()) {
              next(); // Allow access
            } else {
              next('/login'); // Redirect to login page
            }
        },
    },
    {
        path: '/dashboard/category/create',
        name: 'CategoryCreatePage',
        component: () => import('@/dashboard/modules/category/CreatePage'), 
        meta: { layout: DashboardLayout },
        beforeEnter: (to, from, next) => {
            // Your access control logic here
            if (userIsAuthenticated()) {
              next(); // Allow access
            } else {
              next('/login'); // Redirect to login page
            }
        },
    },
    {
        path: '/dashboard/category/edit/:id',
        name: 'CategoryEditPage',
        component: () => import('@/dashboard/modules/category/CreatePage'), 
        meta: { layout: DashboardLayout },
        beforeEnter: (to, from, next) => {
            // Your access control logic here
            if (userIsAuthenticated()) {
              next(); // Allow access
            } else {
              next('/login'); // Redirect to login page
            }
        },
    },
    {
        path: '/dashboard/variant',
        name: 'VariantList',
        component: () => import('@/dashboard/modules/variant/ListPage'), 
        meta: { layout: DashboardLayout },
        beforeEnter: (to, from, next) => {
            // Your access control logic here
            if (userIsAuthenticated()) {
              next(); // Allow access
            } else {
              next('/login'); // Redirect to login page
            }
        },
    },
    {
        path: '/dashboard/variant/create',
        name: 'VariantCreatePage',
        component: () => import('@/dashboard/modules/variant/CreatePage'), 
        meta: { layout: DashboardLayout },
        beforeEnter: (to, from, next) => {
            // Your access control logic here
            if (userIsAuthenticated()) {
              next(); // Allow access
            } else {
              next('/login'); // Redirect to login page
            }
        },
    },
    {
        path: '/dashboard/variant/edit/:id',
        name: 'VariantEditPage',
        component: () => import('@/dashboard/modules/variant/CreatePage'), 
        meta: { layout: DashboardLayout },
        beforeEnter: (to, from, next) => {
            // Your access control logic here
            if (userIsAuthenticated()) {
              next(); // Allow access
            } else {
              next('/login'); // Redirect to login page
            }
        },
    },
    {
        path: '/dashboard/supply',
        name: 'SupplyList',
        component: () => import('@/dashboard/modules/supply/ListPage'), 
        meta: { layout: DashboardLayout },
        beforeEnter: (to, from, next) => {
            // Your access control logic here
            if (userIsAuthenticated()) {
              next(); // Allow access
            } else {
              next('/login'); // Redirect to login page
            }
        },
    },
    {
        path: '/dashboard/supply/new',
        name: 'NewSupply',
        component: () => import('@/dashboard/modules/supply/CreatePage'), 
        meta: { layout: DashboardLayout },
        beforeEnter: (to, from, next) => {
            // Your access control logic here
            if (userIsAuthenticated()) {
              next(); // Allow access
            } else {
              next('/login'); // Redirect to login page
            }
        },
    },
    {
        path: '/dashboard/supply/edit/:id',
        name: 'EditSupply',
        component: () => import('@/dashboard/modules/supply/CreatePage'), 
        meta: { layout: DashboardLayout },
        beforeEnter: (to, from, next) => {
            // Your access control logic here
            if (userIsAuthenticated()) {
              next(); // Allow access
            } else {
              next('/login'); // Redirect to login page
            }
        },
    },
    {
        path: '/dashboard/transfer',
        name: 'TransferList',
        component: () => import('@/dashboard/modules/transfer/ListPage'), 
        meta: { layout: DashboardLayout }
    },
    {
        path: '/dashboard/transfer/new',
        name: 'NewTransfer',
        component: () => import('@/dashboard/modules/transfer/CreatePage'), 
        meta: { layout: DashboardLayout }
    },
    // storage
    {
        path: '/dashboard/storage-type',
        name: 'StorageTypeList',
        component: () => import('@/dashboard/modules/storage/TypeListPage'), 
        meta: { layout: DashboardLayout },
        beforeEnter: (to, from, next) => {
            // Your access control logic here
            if (userIsAuthenticated()) {
              next(); // Allow access
            } else {
              next('/login'); // Redirect to login page
            }
        },
    },
    {
        path: '/dashboard/storage-type/create',
        name: 'CreateStorageType',
        component: () => import('@/dashboard/modules/storage/TypeCreatePage'), 
        meta: { layout: DashboardLayout },
        beforeEnter: (to, from, next) => {
            // Your access control logic here
            if (userIsAuthenticated()) {
              next(); // Allow access
            } else {
              next('/login'); // Redirect to login page
            }
        },
    },
    {
        path: '/dashboard/storage-type/edit/:id',
        name: 'EditStorageType',
        component: () => import('@/dashboard/modules/storage/TypeCreatePage'), 
        meta: { layout: DashboardLayout },
        beforeEnter: (to, from, next) => {
            // Your access control logic here
            if (userIsAuthenticated()) {
              next(); // Allow access
            } else {
              next('/login'); // Redirect to login page
            }
        },
    },
    {
        path: '/dashboard/storage/new',
        name: 'CreateStorage',
        component: () => import('@/dashboard/modules/storage/CreatePage'), 
        meta: { layout: DashboardLayout },
        beforeEnter: (to, from, next) => {
            // Your access control logic here
            if (userIsAuthenticated()) {
              next(); // Allow access
            } else {
              next('/login'); // Redirect to login page
            }
        },
    },
    {
        path: '/dashboard/storage/edit/:id',
        name: 'EditStorage',
        component: () => import('@/dashboard/modules/storage/CreatePage'), 
        meta: { layout: DashboardLayout },
        beforeEnter: (to, from, next) => {
            // Your access control logic here
            if (userIsAuthenticated()) {
              next(); // Allow access
            } else {
              next('/login'); // Redirect to login page
            }
        },
    },
    {
        path: '/dashboard/storage',
        name: 'StorageList',
        component: () => import('@/dashboard/modules/storage/ListPage'), 
        meta: { layout: DashboardLayout },
        beforeEnter: (to, from, next) => {
            // Your access control logic here
            if (userIsAuthenticated()) {
              next(); // Allow access
            } else {
              next('/login'); // Redirect to login page
            }
        },
    },
    // waste
    {
        path: '/dashboard/waste/new',
        name: 'CreateWaste',
        component: () => import('@/dashboard/modules/waste/CreatePage'), 
        meta: { layout: DashboardLayout }
    },
    {
        path: '/dashboard/waste',
        name: 'WasteList',
        component: () => import('@/dashboard/modules/waste/ListPage'), 
        meta: { layout: DashboardLayout }
    },
    
    // orders
    {
        path: '/dashboard/orders',
        name: 'OrderPage',
        component: () => import('@/dashboard/modules/order/ListPage'), 
        meta: { layout: DashboardLayout },
        beforeEnter: (to, from, next) => {
            // Your access control logic here
            if (userIsAuthenticated()) {
              next(); // Allow access
            } else {
              next('/login'); // Redirect to login page
            }
        },
    },
    {
        path: '/dashboard/orders/invoice/:id',
        name: 'InvoicePage',
        component: () => import('@/dashboard/modules/order/InvoicePage'), 
        meta: { layout: DashboardLayout }
    },
    {
        path: '/dashboard/orders/edit/:id',
        name: 'EditPage',
        component: () => import('@/dashboard/modules/order/EditPage'), 
        meta: { layout: DashboardLayout },
        beforeEnter: (to, from, next) => {
            // Your access control logic here
            if (userIsAuthenticated()) {
              next(); // Allow access
            } else {
              next('/login'); // Redirect to login page
            }
        },
    },
    // sales
    {
        path: '/dashboard/sales',
        name: 'SalePage',
        component: () => import('@/dashboard/modules/sales/ListPage'), 
        meta: { layout: DashboardLayout },
        beforeEnter: (to, from, next) => {
            // Your access control logic here
            if (userIsAuthenticated()) {
              next(); // Allow access
            } else {
              next('/login'); // Redirect to login page
            }
        },
    },
    {
      path: '/dashboard/saleses/edit/:id',
      name: 'EditSalesPage',
      component: () => import('@/dashboard/modules/sales/EditPage'), 
      meta: { layout: DashboardLayout },
      beforeEnter: (to, from, next) => {
          // Your access control logic here
          if (userIsAuthenticated()) {
            next(); // Allow access
          } else {
            next('/login'); // Redirect to login page
          }
      },
  },
    {
        path: '/dashboard/settings',
        name: 'SettingsPage',
        component: () => import('@/dashboard/modules/SettingsPage'), 
        meta: { layout: DashboardLayout },
        beforeEnter: (to, from, next) => {
            // Your access control logic here
            if (userIsAuthenticated()) {
              next(); // Allow access
            } else {
              next('/login'); // Redirect to login page
            }
        },
    },
    {
        path: '/test',
        name: 'TestPage',
        component: () => import('@/website/modules/TestPayment'), 
        meta: { layout: DashboardLayout }
    },
    {
        path: '/:catchAll(.*)*',
        name: 'NotFound',
        component: () => import('@/dashboard/modules/NotFound'), 
        meta: { layout: WebsiteLayout }
     }
]

function userIsAuthenticated() {
    let user = localStorage.getItem('user')
    return JSON.parse(user).token
}

var router = createRouter({
    history: createWebHistory('/'),
    routes
})

export default router