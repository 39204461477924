<template>
    <div class="thumbnail">

    </div>
</template>

<style scoped>
.thumbnail {
    width: 20px;
    height: 20px;
    border-radius: 50%; 
    background: red;
}
</style>