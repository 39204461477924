import { defineStore } from 'pinia';

export const useCartStore = defineStore({
    id: 'cart',
    state: () => ({
        cart: {
            count: 0,
            items: [],
            total: 0
        },
        orderResponse: {},
        orderItems: [],
        customerInfo: {},
    }),
    actions: {
        async addItemsToCart(item){
            this.cart.items.push(item)
            this.cart.total += Number(item.total)
            this.cart.count += Number(item.quantity)
            console.log(this.cart)
        },

        async updateCart() {
            const priceSum = this.cart.items.reduce((accumulator, object) => {
                return accumulator + object.total;
            }, 0);

            const quantitySum = this.cart.items.reduce((accumulator, object) => {
                return accumulator + object.quantity;
            }, 0);

            this.cart.total = Number(priceSum)
            this.cart.count = Number(quantitySum)
        }
    }
});

