import { defineStore } from 'pinia';
import axios from 'axios'

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({

    }),
    actions: {
        async login(api, credentials) {
            const resp = await axios.post(process.env.VUE_APP_BASE_API_URL + api, credentials)
            localStorage.setItem('user', JSON.stringify({
                token: resp.data.token,
                userInfo: resp.data.userInfo
            }))
            if(resp.data.token) {
                return true
            } else {
                return false
            }
        },

        async logout() {
            let user = JSON.parse(localStorage.getItem('user')) 
            const resp = await axios.post(process.env.VUE_APP_BASE_API_URL + 'logout', {}, {
                headers: {
                    'Authorization': 'Bearer ' + user.token, 
                }
            })

            if(resp.data.status == 200) {
                localStorage.removeItem('user')
                return true
            } else {
                return false
            }
        }
    }
});

